const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";

export const app_mode = localhost
  ? "localhost"
  : development
  ? "development"
  : "production";

export const contentPath =
  process.env.REACT_APP_BasePath ??
  (localhost
    ? "http://localhost:3039/content/api/" 
    : development
    ? "http://192.168.18.66:6063/content/api/"
    : "https://api.ilmiya.com/content/api/"
    );

export const APIurls = {
  // auth
  login: "auth/login",
  register: "auth/register",
  authenticate: "auth/authenticate",
  logout: "auth/logout",
  reset: "auth/reset-password",
  forget: "auth/forgot-password",
  history: "scrap-history",
  refreshTokens: "/auth/refresh-tokens",
  user: "/users",
  subscription: "subscription",

};

export const BasePath = process.env.REACT_APP_SERVER_DOMAIN_URL;
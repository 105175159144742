import React, { useState,useEffect } from "react";
import { RiClapperboardFill } from "react-icons/ri";
import ShortenNumber from "../../utilities/ShortenNumber"
import Loaderfile from "../Loader/Loaderfile";

const Detailscard = ({ userInfo }) => {
  console.log("🚀 ~ Detailscard ~ userInfo:", userInfo);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userInfo) {
      setLoading(false);
    }
  }, [userInfo]);

  const [profileData, setProfileData] = useState({
    platform: "tiktok",
    username: "bellapoarch",
    usernameInput: "bellapoarch",
    id: "6748458643983238149",
    name: "Bella Poarch",
    url: "https://tiktok.com/@bellapoarch",
    bio: "CRUSH OUT NOW!!!!🫶🏻💘",
    followerCount: 92900000,
    videoCount: 616,
    heartCount: -2012462451,
    followingCount: 613,
    avatar: require("../../assets/bellaprofile.jpeg"),
  });

  if (loading) {
    return <Loaderfile />;
  }

  return (
    <div>
      <div className="deatil-card bg-gray-800 shadow rounded-lg m-auto  py-6 sm:px-6 w-11/12">
        <div className="card-content flex lg:items-center items-start justify-evenly sm:flex-row flex-col sm:gap-0 gap-7">
          <div className="flex items-center" style={{ marginLeft: "16px" }}>
            <div className="flex-shrink-0">
              <img
                className="rounded-full object-cover xxs:w-20 xxs:h-20 w-24 h-24"
                // src="images/Beast.jpg"
                src={userInfo?.data?.userInfo?.user?.avatarLarger || profileData?.avatar}
                alt="Profile-img"
              />
              <div className="-mt-24 w-24 xxs:hidden">
                <svg
                  className="CircularProgressbar "
                  viewBox="0 0 100 100"
                  data-test-id="CircularProgressbar"
                  style={{ margin: "0" }}
                >
                  <path
                    className="CircularProgressbar-trail"
                    d=" M 50,50 m 0,-48.5 a 48.5,48.5 0 1 1 0,97 a 48.5,48.5 0 1 1 0,-97 "
                    strokeWidth="3"
                    fillOpacity="0"
                    style={{
                      strokeDasharray: "304.734px, 304.734px",
                      strokeDashoffset: "182.841px",
                    }}
                  ></path>
                  <path
                    className="CircularProgressbar-path"
                    d="M 50,50m 0,-48.5a 48.5,48.5 0 1 1 0,97a 48.5,48.5 0 1 1 0,-97"
                    strokeWidth="3"
                    fillOpacity="0"
                    style={{
                      stroke: "rgb(255, 0, 80)",
                      strokeDasharray: "304.734px, 304.734px",
                      strokeDashoffset: "182.841px",
                    }}
                  ></path>
                </svg>
              </div>
            </div>
            <div className="sm:ml-4">
              <p
                className="text-gray-200  xxs:text-xl text-2xl xs:mt-1 mt-3 truncate mx-3 text-left "
                style={{ width: "150px" }}
              >
                {/* Username */}
                {userInfo?.data?.userInfo?.user?.nickname || profileData?.name}
              </p>
              <p className=" xxs:text-xs text-gray-400 truncate mx-3 text-left">
                {/* @id */}
                {userInfo?.data?.userInfo?.user?.id || profileData?.id}
              </p>
            </div>
          </div>

          <div
            className=" result-cards flex lg:flex-row flex-col px-4 gap-5"
            style={{ Width: "100%" }}
          >
            <div
              className="card flex-row justify-between p-4"
              style={{
                width: "100%",
                height: "111px",
                background: "rgb(28 33 56)",
              }}
            >
              <div>
                <p
                  className="text-xs+ uppercase text-left"
                  style={{ color: "#64748b", fontSize: "12px" }}
                >
                  Videos
                </p>
                <div
                  className="mt-8 flex items-baseline space-x-1"
                  style={{ marginTop: "10px" }}
                >
                  <p
                    className="text-2xl font-semibold text-gray-300"
                    style={{ fontSize: "20px" }}
                  >
                    {/* 616 */}
                    {ShortenNumber(userInfo?.data?.userInfo?.stats?.videoCount || profileData?.videoCount)}
                  </p>
                </div>
              </div>
              <div className="mask is-squircle flex h-10 w-10 items-center justify-center bg-info/10">
                <i className=" text-xl text-info">
                  <RiClapperboardFill />
                </i>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <RiClapperboardFill className=" translate-x-1/4 translate-y-1/4 text-5xl opacity-15" />
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="heart"
                  className="svg-inline--fa fa-heart translate-x-1/4 translate-y-1/4 text-5xl opacity-15"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                  ></path>
                </svg>
              </div>
            </div>

            <div
              className="card flex-row justify-between p-4"
              style={{
                width: "100%",
                height: "111px",
                background: "rgb(28 33 56)",
              }}
            >
              <div>
                <p
                  className="text-xs+ uppercase"
                  style={{ color: "#64748b", fontSize: "12px" }}
                >
                  Likes
                </p>
                <div
                  className="mt-8 flex items-baseline space-x-1"
                  style={{ marginTop: "10px" }}
                >
                  <p
                    className="text-2xl font-semibold text-gray-300"
                    style={{ fontSize: "20px" }}
                  >
                    {/* 2.0b */}
                    {ShortenNumber(userInfo?.data?.userInfo?.stats?.heartCount || profileData?.heartCount)}
                  </p>
                </div>
              </div>
              <div className="mask is-squircle flex h-10 w-10 items-center justify-center bg-info/10">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="heart"
                  className="svg-inline--fa fa-heart inline-block text-sm w-4 text-red-500"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                  ></path>
                </svg>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="eye"
                  className="svg-inline--fa fa-eye translate-x-1/4 translate-y-1/4 text-5xl opacity-15"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M288.61 64C133.28 64 0 256 0 256s133.28 192 288.61 192C443.94 448 576 256 576 256S443.94 64 288.61 64zm0 315.38c-68.48 0-123.77-55.3-123.77-123.79s55.3-123.79 123.77-123.79 123.77 55.3 123.77 123.79-55.28 123.79-123.77 123.79z"
                  />
                </svg>
              </div>
            </div>

            <div
              className="card flex-row justify-between p-4"
              style={{
                width: "100%",
                height: "111px",
                background: "rgb(28 33 56)",
              }}
            >
              <div>
                <p
                  className="text-xs+ uppercase"
                  style={{ color: "#64748b", fontSize: "12px" }}
                >
                  FOLLOWERS
                </p>
                <div
                  className="mt-8 flex items-baseline space-x-1"
                  style={{ marginTop: "10px" }}
                >
                  <p
                    className="text-2xl font-semibold text-gray-300"
                    style={{ fontSize: "20px" }}
                  >
                    {/* 92.9m */}
                    {ShortenNumber(userInfo?.data?.userInfo?.stats?.followerCount || profileData?.followerCount)}
                  </p>
                </div>
              </div>
              <div className="mask is-squircle flex h-10 w-10 items-center justify-center bg-info/10">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="users"
                  className="svg-inline--fa fa-users inline-block text-sm w-4 text-blue-500"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    style={{ color: "#ff9800" }}
                    fill="currentColor"
                    d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                  ></path>
                </svg>
              </div>
              <div className="absolute bottom-0 right-0 overflow-hidden rounded-lg">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="users"
                  className="svg-inline--fa fa-users translate-x-1/4 translate-y-1/4 text-5xl opacity-15"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="currentColor"
                    d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Detailscard;

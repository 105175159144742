


const HandleThankYouMessage = (setShowThankYou) => {
    const url = new URL(document.URL);
    const thankYouShown = localStorage.getItem('thankYouShown');

    if (url.pathname === '/thank-you' && !thankYouShown) {
        setShowThankYou(true);
        // Remove thank-you from the URL
        window.history.replaceState({}, document.title, '/');
        // Save the flag to local storage
        localStorage.setItem('thankYouShown', 'true');
    } else if (thankYouShown) {
        // Remove the flag from local storage if the user visits a different page
        localStorage.removeItem('thankYouShown');
    }
}

export default HandleThankYouMessage;
import React, { useState } from "react";
import { BiSolidError } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

const Errorshow = ({ getPlayLists }) => {
  const [showBox, setShowBox] = useState(true);

  const handleClose = () => {
    setShowBox(false);
  };

  if (!showBox) {
    return null;
  }

  return (
    <div className="w-full max-w-[500px] m-auto mt-2">
      <div className="block  rounded-lg shadow-sm py-3 sm:px-4 px-3 m-auto ">
        <div className="flex flex-col items-start text-gray-900  items-center">
          {/* <div className="flex items-center justify-between w-full">
                        <div className='flex items-center gap-2'>
                            <BiSolidError size={20} className='text-red-500'/>
                            <p className='text-xl font-semibold opacity-95'>oops!</p>
                        </div>
                        <IoClose size={25} onClick={handleClose} className="cursor-pointer text-gray-500"/>
                    </div> */}
          <p className="opacity-90 text-white">User have no playlist</p>
        </div>
      </div>
    </div>
  );
};

export default Errorshow;

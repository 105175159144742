import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoMdDownload } from "react-icons/io";
import { AiOutlineLoading } from "react-icons/ai";

import { Link } from "react-router-dom";
import ShortenNumber from "../../utilities/ShortenNumber";

const Playlistvideo = ({ item, i }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
console.log("process.env.REACT_APP_NODE_SERVER_URL",process.env.REACT_APP_NODE_SERVER_URL)
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log("mixId>>>>>>>>>>>>>>>>>>>>>", item?.mixId);

  const [playlistDetail, setPlaylistDetail] = useState();
  console.log("🚀 ~ Playlistvideo ~ playlistDetail:", playlistDetail);

  const [cursor, setCursor] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [singleLoading, setSingleLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  

  const getPlayListDetails = async (cursor) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://bogustokscrpapi.toktools.online/v1/tiktok/user-playlist-detail?mixId=${
          item?.mixId
        }${cursor ? `&cursor=${cursor}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
          mode: "cors",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPlaylistDetail((prevDetail) => {
        if (prevDetail && prevDetail.data && prevDetail.data.itemList) {
          return {
            ...prevDetail,
            data: {
              ...prevDetail.data,
              itemList: [...prevDetail.data.itemList, ...data.data.itemList],
            },
          };
        }
        return data;
      });
      setCursor(data.data.cursor);
      setHasMore(data.data.hasMore);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user info:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPlayListDetails();
  }, []);

  const downloadSingleVideo = (item) => {
    console.log("callllll");
    setSingleLoading(true);
    
    axios
      .post(`${process.env.REACT_APP_NODE_SERVER_URL}v1/api/single/url/video`, {
        link: `https://www.tiktok.com/@${item?.uniqueId}/video/${item?.id}`,
      })
      .then((response) => {
        console.log("Success:", response.data);
        
        // Assuming the ID you need is in response.data.id
        const id = response.data.id;
  
        // Make the second API call with the ID
        return axios.get(`${process.env.REACT_APP_NODE_SERVER_URL}v1/api/single/download/video/${id}`, {
          responseType: 'arraybuffer', // Set response type to 'arraybuffer' to handle binary data
        });
      })
      .then((response) => {
        console.log("Second API call success:", response.data);
  
        // Create a Blob from the binary data
        const blob = new Blob([response.data], { type: 'video/mp4' });
        
        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `video_${item?.id}.mp4`; // Set the download filename
  
        // Append the link to the body
        document.body.appendChild(link);
  
        // Programmatically click the link to trigger the download
        link.click();
  
        // Remove the link from the document
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error:", error);
      setSingleLoading(false);

      });
  };

  const downloadBulkVideo = (item) => {
    console.log("🚀 ~ downloadBulkVideo ~ item:", item);
    console.log("callllll bulk");
  
    // Ensure item and itemList are defined before mapping
    if (item && item.data?.itemList) {
      // Map the itemList to create an array of URLs
      let allData = item.data.itemList.map(video => `https://www.tiktok.com/@${video.uniqueId}/video/${video.id}`);
  
        setBulkLoading(true);

      // Post the array of URLs to the server
      axios
        .post(`${process.env.REACT_APP_NODE_SERVER_URL}v1/api/bulk/urls/videos`, {
          links: allData
        })
        .then((response) => {
          console.log("Success:", response.data);
  
          // Handle the response if it's not in JSON format
          let responseData;
          if (typeof response.data === 'string') {
            console.log("data is in string format>>>>")
            try {
              // Extract the JSON part from the response string
              const jsonStr = response.data.match(/\{.*\}/)[0];
              responseData = JSON.parse(jsonStr);
              console.log("🚀 ~ .then ~ responseData:", responseData)
            } catch (e) {
              console.error("Failed to parse response data:", e);
                            setBulkLoading(false);

              return;
            }
          } else {
            responseData = response.data;
          }
  
          // Assuming the videosFolderName is in responseData.videosFolderName
          const videosFolderName = responseData.videosFolderName;
          console.log("🚀 ~ .then ~ videosFolderName:", videosFolderName)
  
          // Check if videosFolderName is defined
          if (!videosFolderName) {
            console.error("videosFolderName is undefined");
                        setBulkLoading(false);

            return;
          }
  
          // Make the second API call to download the zip file of the videos folder
          return axios.get(`${process.env.REACT_APP_NODE_SERVER_URL}v1/api/bulk/download/${videosFolderName}`, {
            responseType: 'arraybuffer', // Set response type to 'arraybuffer' to handle binary data
          });
        })
        .then((response) => {
          console.log("Second API call success:", response.data);
  
          // Create a Blob from the binary data
          const blob = new Blob([response.data], { type: 'application/zip' });
          
          // Create a link element
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          // link.download = `${item.data.itemList[0]?.uniqueId}_videos.zip`; // Set the download filename
          link.download = `download_videos.zip`; // Set the download filename
  
          // Append the link to the body
          document.body.appendChild(link);
  
          // Programmatically click the link to trigger the download
          link.click();
  
          // Remove the link from the document
          document.body.removeChild(link);
              setBulkLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
              setBulkLoading(false);
        });
    } else {
      console.error("Invalid item or itemList");
    }
  };
  
  
  
  
  return (
    <div className="App" key={i}>
      {/* Model btn */}
      <button
        className="bg-[#ffffff14] text-white rounded-md p-4 w-full hover:bg-[#ffffff28] transition h-full"
      >
        <div className="flex items-center justify-between gap-1 ">
          <div className="flex items-center gap-4 "
           onClick={openModal}
          >
            <div className="w-[72px] h-[72px] rounded-[2px] flex items-center justify-center	overflow-hidden">
              <img
                // src="images/Beast.jpg"
                src={item?.cover}
                alt=""
                className="w-[72px] h-[72px] rounded-[2px] object-cover"
              />
            </div>
            <div className="flex flex-col gap-5 items-start">
              <div className="text-base font-semibold opacity-90 text-start">
                {item?.name || "N/A"}{" "}
              </div>
              <div className="text-sm opacity-50 font-medium">
                {item?.videoCount || "N/A"}
              </div>
            </div>
          </div>
          {/* Bulk Download Btn */}
          <div onClick={()=>downloadBulkVideo(playlistDetail)}>
            <button className="small-btn bg-[#111827] rounded-[50px] px-[8px] py-[8px]">
              {bulkLoading ? <AiOutlineLoading/> : <IoMdDownload size={20} />}
            </button>
          </div>
        </div>
      </button>

      {/* Model */}
      {isModalOpen && (
        <div
          id="modelConfirm"
          className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
        >
          <div className="relative top-40 mx-auto shadow-xl rounded-md bg-[#353535] max-w-[692px] sm:w-11/12 w-[95%]">
            <div
              className="flex justify-end px-2 py-5"
              style={{ borderBottom: "0.5px solid rgba(255, 255, 255, 0.2)" }}
            >
              {/* Heading */}
              <div className="ml-auto">
                <p className="text-[24px] text-[#fffffffE6] font-bold">
                  {playlistDetail &&
                    playlistDetail?.data &&
                    playlistDetail?.data?.itemList[0]?.music?.title}{" "}
                  {"(" + playlistDetail?.data?.itemList?.length + ")"}
                </p>
              </div>

              {/* Model close btn */}
              <button
                onClick={closeModal}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="py-3 max-h-[400px] overflow-x-auto">
              <div className="flex flex-col gap-5">
                {playlistDetail?.data?.itemList?.map((item, i) => (
                  <div key={i}>
                    <div className="flex items-center justify-between gap-2 px-2 bg-transparent hover:bg-[#3535355b]">
                      <div className="flex items-center gap-3 grow">
                        {/* Number */}
                        <p style={{ wordBreak: "auto-phrase" }}>{i + 1}</p>
                        {/* IMG */}
                        <Link
                          to={`https://www.tiktok.com/@${item?.uniqueId}/video/${item?.id}`}
                          target="_blank"
                        >
                          <div className="w-[80px] h-[106px] rounded-[1px] flex items-center justify-center overflow-hidden">
                            <img
                              // src="images/Beast.jpg"
                              src={item?.video?.cover}
                              alt=""
                              className="w-[80px] h-[106px] rounded-[1px] object-cover"
                            />
                          </div>
                        </Link>

                        {/* Details */}
                        <div className="flex flex-col items-start">
                          <p className="text-base font-semibold text-[#fffffffE6] text-start description-model">
                            {item?.desc}
                          </p>
                          <p className="text-sm opacity-50 font-medium">
                            {ShortenNumber(item?.stats?.playCount)} views
                          </p>
                        </div>
                      </div>

                      <div onClick={() => downloadSingleVideo(item)}>
                        <button className="small-btn bg-[#111827] rounded-[50px] px-[8px] py-[8px]">
                           <IoMdDownload size={20} />
                          {/* {loading ? <AiOutlineLoading/> : <IoMdDownload size={20} />} */}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {hasMore && !loading && (
                <button
                  onClick={() => getPlayListDetails(cursor)}
                  className="bg-[#ffffff14] text-white rounded-md p-4 w-full hover:bg-[#ffffff28] transition mt-4"
                >
                  Load More
                </button>
              )}
              {loading && (
                <div className="text-center mt-4">
                  <p>Loading...</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Playlistvideo;

import Button from "react-bootstrap/Button";
import React, { useEffect, useState, useRef } from "react";
import { BiError } from "react-icons/bi";
import clsx from "clsx";
// import ProfileInfo from "../components/ProfileInfo.js";
// import VideosList from "../components/VideosList.js";
import useAuth from "../hooks/useAuth.js";
import HandleThankYouMessage from "../utilities/HandleThankYouMessage";
import TiktokCircleImage from "../assets/images/tiktokcircle.png";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import Detailscard from "../components/Cards/Detailscard.jsx";
import Playlistcard from "../components/Cards/Playlistcard.jsx";

function HomePage({ isEmbedded }) {
  const { user, isLoggedIn } = useSelector((s) => s.auth);
  console.log("auth", user, isLoggedIn);

  const [showThankYou, setShowThankYou] = useState(false);
  useEffect(() => {
    HandleThankYouMessage(setShowThankYou);
  }, []);

  const { userData, loading, setUserData } = useAuth();

  console.log({ userData });

  const [
    scrapingAccountVideosErrorMessage,
    setScrapingAccountVideosErrorMessage,
  ] = useState("");

  const [licenseKey, setLicenseKey] = useState("");
  const [selectedVideos, setSelectedVideos] = useState(new Set());

  console.log({ selectedVideos });

  useEffect(() => {
    if (!localStorage.getItem("licenseKey")) return;
    setLicenseKey(localStorage.getItem("licenseKey"));
  }, []);

  const modalOpenRef = useRef(null);
  const isUrl = (input) => {
    const urlPattern = /^(https?:\/\/www\.|www\.)/;
    if (urlPattern.test(input)) {
      return true;
    }
    try {
      new URL(input);
      return true;
    } catch (_) {
      return false;
    }
  };

  const [showVideosList, setShowVideosList] = useState(false);

  const token = localStorage.getItem("app-access-token");
  const [userInfo, setUserInfo] = useState(null);
  const [playlistData, setPlaylistData] = useState(null);
  const [usernameInput, setUsernameInput] = useState(""); // Assuming you have a state for username input

  console.log("🚀 ~ HomePage ~ userInfo:", userInfo);
  console.log("🚀 ~ HomePage ~ playlistData:", playlistData);

  const handelSearchUserInfo = async () => {
    try {
        setUserInfo("");

        const corsProxy = "https://cors-anywhere.herokuapp.com/";
        const targetURL = `https://bogustokscrpapi.toktools.online/v1/tiktok/userinfo?username=${usernameInput}`;
        
        const response = await fetch(
          // corsProxy + targetURL,
          targetURL,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "GET",
                mode: "cors",
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setUserInfo(data);
    } catch (error) {
        console.error("Error fetching user info:", error);
    }
};


  useEffect(() => {
    const fetchPlaylistData = async (secUid) => {
      try {
        setPlaylistData("")
        const response = await fetch(
          `https://bogustokscrpapi.toktools.online/v1/tiktok/user-playlists?secUid=${secUid}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token ? `Bearer ${token}` : "",
            },
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const playlist = await response.json();
        console.log("🚀 ~ fetchPlaylistData ~ playlist:", playlist);
        setPlaylistData(playlist);
      } catch (error) {
        console.error("Error fetching playlist data:", error);
      }
    };

    if (userInfo?.data?.userInfo?.user?.secUid) {
      fetchPlaylistData(userInfo.data.userInfo.user.secUid);
    }
  }, [userInfo]);

  return (
    <div
      className="App"
      style={{
        paddingTop: !isEmbedded && "160px",
        paddingBlock: isEmbedded && "20px",
      }}
    >
      {showThankYou && (
        <div style={{ height: "250px", marginBottom: "160px" }}>
          <h1
            className=" text-gradient__amber"
            style={{ fontSize: "70px", fontWeight: "700" }}
          >
            Thank you!
          </h1>
          <span style={{ color: "#ccdae7", marginTop: "20px" }}>
            You're all set!
          </span>
          <button
            style={{ color: "#ccdae7" }}
            className={clsx(
              "inline-block mt-20 px-2 py-3  text-white font-bold text-sm leading-snug uppercase rounded shadow-md  hover:shadow-lg  focus:outline-none focus:ring-0  focus:shadow-lg transition duration-150 ease-in-out "
            )}
          >
            Start using TokBackup
          </button>
          <svg className="arrows" style={{ scale: "0.7" }}>
            <path className="a1" d="M0 0 L30 32 L60 0"></path>
            <path className="a2" d="M0 20 L30 52 L60 20"></path>
            <path className="a3" d="M0 40 L30 72 L60 40"></path>
          </svg>
        </div>
      )}

      <div className="hero-section max-w-[500px] w-11/12">
        <div className="top-icons">
          <div
            style={{
              width: "54px",
              height: "54px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="icons"
          >
            <img
              className="transition-all duration-300"
              src={TiktokCircleImage}
              style={{
                width: "51px",
                height: "51px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
        <div
          className="mb-10  "
          style={{
            opacity: loading && "0.5",
            pointerEvents: loading && "none",
          }}
        >
          <div className="search-bar relative m-auto sm:w-3/4 w-11/12">
            {!isUrl(usernameInput) && <p className="tag-icon">@</p>}
            <input
              type="text"
              value={usernameInput}
              onChange={(e) => setUsernameInput(e.target.value)}
              placeholder={" Add username or Link"}
              data-e2e="common-StringInput-TUXTextInput"
              className="input-field bg-gray-800 transition ease-in-out border border-gray-700 focus:border-blue-600 focus:outline-none"
            />
          </div>

          <div className="search-btn">
            <Button
              className="border-[#ff0050] border-2 text-[#ff0050] bg-transparent hover:bg-[#ff0050] hover:text-[#fff]  w-full flex items-center justify-center gap-2 font-medium py-3"
              onClick={handelSearchUserInfo}
            >
              Search
              <span>
                <FaSearch />
              </span>
            </Button>
          </div>
        </div>
      </div>
      {/* ================================================================================================== */}
      {/* ================================================================================================== */}
      {/* ================================================================================================== */}
      <Detailscard userInfo={userInfo} />
      <Playlistcard playlistData={playlistData} userInfo={userInfo} usernameInput={usernameInput} />
      <button
        ref={modalOpenRef}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        className="fixed hide"
      ></button>
    </div>
  );
}

export default HomePage;



import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase-config.js'

import FetchUserData from '../utilities/FetchUserData.js';



// Custom hook for handling user authentication and fetching user data
const useAuth = () => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const fetchedUserData = await FetchUserData(currentUser.uid);
                setUserData(fetchedUserData);
            } else {
                setUserData(null);
            }
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, [auth]);

    return { userData, loading, setUserData };
};

export default useAuth;


import React from "react";
import { BiGrid } from "react-icons/bi";
import { FaList, FaSearch } from "react-icons/fa";
import Playlistvideo from "../Models/Playlistvideo";
import Listview from "../Models/Listview.jsx";
import Errorshow from "../Errorshow/Errorshow.jsx";

const Playlistcard = ({ playlistData }) => {
  let getPlayLists = playlistData?.data?.playList;
  console.log("🚀 ~ Playlistcard ~ getPlayLists:", getPlayLists);
  return (
    <div>
      <div
        style={{
          width: "90%",
          maxWidth: "1260px",
          margin: "auto",
          marginTop: "80px",
        }}
      >
        <div
          className="block bg-gray-800 rounded-xl shadow-sm py-5 sm:px-5 px-3"
          style={{ marginTop: "20px" }}
        >
          <div className="flex items-center justify-between mb-5 gap-4 flex-wrap">
            {/* Search bar */}
            <div className="flex items-center justify-start">
              <div
                className="bg-gray-800 relative"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="text"
                  placeholder={"Search Videos"}
                  style={{
                    paddingRight: "50px",
                    background: "rgb(17 24 39 / 1)",
                  }}
                  className="css-5g0doo rounded-3xl bg-transparent transition ease-in-out border border-gray-800  focus:border-blue-600 focus:outline-none"
                />
                <div className="text-gray-500 absolute right-4">
                  <FaSearch />
                </div>
              </div>
            </div>

            {/* Grid/List view */}
            <div className="flex items-center justify-end ">
              <div>
                <button className="small-btn bg-[#111827] rounded-[4px] px-3 py-2">
                  <BiGrid size={25} />
                </button>
                <button className="small-btn rounded-[4px] px-3 py-2">
                  <FaList size={25} />
                </button>
              </div>
            </div>
          </div>

          {/* */}
          {getPlayLists && getPlayLists.length > 0 ? (
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 gap-5">
              {getPlayLists.map((item, i) => (
                <Playlistvideo key={i} item={item} i={i} />
              ))}
            </div>
          ) : (
            <div className="gap-5">
              <Errorshow getPlayLists={getPlayLists} />
            </div>
          )}

          {/* Render Errorshow only when there's no data */}
          {/* {!getPlayLists && getPlayLists.length === 0 && getPlayLists == undefined && null} */}
        </div>
      </div>
    </div>
  );
};

export default Playlistcard;

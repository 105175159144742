import "./App.scss";
import "./responsive.scss";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Page404 from "./screens/Page404.jsx";
import HomePage from "./screens/HomePage.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <>
      <div className="App">
        <Suspense
          fallback={
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "10",
              }}
              className="loadingio-spinner-interwind-flyom1cz6sv"
            >
              <div className="ldio-zxrz71mlja">
                <div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div className="pages">
            {/* <Header /> */}
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route path="*" element={<Page404 />} />
            </Routes>

            <Footer />
          </div>
        </Suspense>
      </div>
    </>
  );
}

export default App;

import { APIurls, contentPath } from "../constants.js";
import apiService from ".";
import multipart from "./multipart.js";
import axios from "axios";
import { toast } from "react-toastify";

export const ApiRequests = {
  //user Authentication
  login: async (data) => await apiService.post(APIurls.login, data),
  logout: async (data) => await apiService.post(APIurls.logout, data),
  googleLogout: async (data) =>
    await apiService.get(APIurls.googleLogout, data),

  register: async (data) => await apiService.post(APIurls.register, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  refreshTokens: async (data) =>
    await apiService.post(APIurls.refreshTokens, data),
  revokeToken: async (data) => await apiService.post(APIurls.revokeToken, data),

  // organization
  getProfile: async (id) => await apiService.get(APIurls.profile),


  SaveSearchHistory: async (data, token) =>
    await apiService.post(`${contentPath}/${APIurls.history}`, data.data, {
      headers: {
        Authorization: `bearer ${token}`,
      },
    }),

  subscription: async (data, token) => {
    const response = await apiService.patch(
      `${contentPath}/${APIurls.subscription}/update-subscription`,
      data,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );

    return response
  },
  getProducts: async (data) => {
    const response = await apiService.get(
      `${contentPath}/${APIurls.subscription}/packeges?store_id=${data.store_id}`,
      {
        headers: {
          Authorization: `bearer ${data.token}`,
        },
      }
    );
    return response
  },
  getProduct: async (data) => {
    const response = await apiService.get(
      `${contentPath}/${APIurls.subscription}/packeges/${data.id}`,
      {
        headers: {
          Authorization: `bearer ${data.token}`,
        },
      }
    );
    return response
  },
  getLimits: async (data) => {
    try {
      const response = await apiService.get(
        `${contentPath}/video-mimits/get?name=${data}`,
        {
          headers: {
            Authorization: `bearer ${data.token}`,
          },
        }
      );
      return response
    } catch (error) {
      console.log('error ', error.message)
    }
  },
  getUserBoard: async (token) => {
    const response = await apiService.get(
      `${contentPath}/boards/user`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response
  },
  getSharedBoard: async (id,token) => {
    const response = await apiService.get(
      `${contentPath}/boards/shared/${id}/`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response
  },


  getUserVideoBoard: async ({ boardId, token, ...params }) => {
    const response = await apiService.get(
      `${contentPath}/boards/videos/user/?boardId=${boardId}`,
      {
        params,
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
    );
    return response
  },
  getSharedVideoBoard: async ({ boardId, videoid, token, ...params }) => {
    console.log('PARAMS ', params);
  
    // Construct the base URL without videoid
    let url = `${contentPath}/boards/videos/shared/?boardId=${boardId}`;
  
    // Add videoid to the URL if it is defined
    if (videoid !== undefined) {
      url += `&videoid=${videoid}`;
    }
  
    const response = await apiService.get(url, {
      params,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  
    return response;
  },
  



  createBoardVideo: async ({ token, data }) => {
    try {
      const response = await apiService.post(
        `${contentPath}/boards/videos`,
        data,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      console.log('response in save ', response)
      toast.success("Video Saved Successfully")
      return response
    } catch (error) {
      // console.log('error ', error.message)
      // toast.error(error.message)
      if (error.response && error.response.status === 400) {
        // If the error has a response and the status code is 400, display the specific error message
        const errorMessage = error.response.data && error.response.data.message ? error.response.data.message : 'Bad Request';
        console.log('API error message:', errorMessage);
        toast.error(errorMessage);
      } else {
        // For other types of errors, display a generic message
        toast.error(error.message);
      }
    }
  },
};
